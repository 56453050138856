import { useState } from 'react';
import { kernel } from '../../App'
import {Spin} from 'antd'
import logoBig from '../../Images/logoBig.png'
import { Content } from '../../Templates'
import { Page, Icon, ListTitle, Button, ProgressCircular, Row, Col } from 'react-onsenui';
import { Form } from '../../Templates/Form';
import { useNavigate } from "react-router-dom";
import CustomButtom from '../../components/Button'
import { useTranslation, Trans } from "react-i18next";

import {
    notification,
} from 'antd';

const CSScenter = {
    position: "relative",
    textAlign: "center",
}

const fields = [
    {
        name: 'email',
        placeholder: 'Email',
        type: 'text',
        defaultValue: ''
    }
]

export function ForgotPassword({
}) {

    const [status, setStatus] = useState({})
    const [help, setHelp] = useState({})
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const onFinish = async () => {
        const values = user;
        setLoading(true)
        const response = await kernel.api.post('/user/forget', values)
        if (response.error) {
            notification.error({
                description: <>{response.error}</>
            })
            setLoading(false)
            return
        }
        notification.success({
            message: response.data
        })
        setLoading(false)
        navigate('/settings')
    }

    const { t, i18n, ready } = useTranslation();
    
    if (ready) {
        return (
            <Content back="/session/login" backTransition="outRight" topAlign={true}>
                
                <div className="center" style={CSScenter}>
                    <div id="logo" style={{marginTop: 50}}>
                        <img src={logoBig} width={150} />
                    </div>
                </div>
                        
                <ListTitle style={{textAlign: 'center', marginTop: 50, color: '#FFFFFF'}}>{t("ForgotPassword.title")}</ListTitle> 

                <div style={{margin: 30}}>
                    <Form help={help} status={status} values={user} setValues={setUser} fields={fields}/>
                    <Row> 
                        <Col>
                            {
                                !loading ? 
                                    <CustomButtom onClick={onFinish} modifier="cta">
                                        {t("ForgotPassword.resetButton")}
                                    </CustomButtom>
                                :
                                    <ProgressCircular indeterminate />
                            }
                        </Col>
                        <Col>
                            <Button onClick={() => navigate('/session/login')} style={{color: "#FFFFFF"}} modifier="quiet">
                                {t("ForgotPassword.backButton")}
                            </Button>
                        </Col>
                    </Row>
                </div>

            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}