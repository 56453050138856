
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Content } from '../Templates'
import { Modal, Space, Button, Skeleton, Affix, notification } from 'antd'
import { Transition } from '../App'
import { format } from 'timeago.js'
import { useTranslation, Trans } from "react-i18next";

export function Privacy({ kernel }) {
    const [loaded, setLoaded] = useState(true)

    const { t, i18n, ready } = useTranslation();

    if (ready) {
        return (
            <Content back="/dashboard" backTransition="outRight" topAlign={true}>
                <div id="page">


                    <div className="audit" style={{ maxWidth: 700, margin: "0 auto", fontWeight: 500 }}>

                        
                    </div>


                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}