import { useState } from 'react';
import { useEffect } from 'react';
import { Icon, Switch } from 'react-onsenui';
import { useNavigate } from "react-router-dom";
import { Content } from '../../Templates';
import Title from '../../components/Title';
import Paragraph from '../../components/Paragraph';
import SmallText from '../../components/SmallText';
import Button from '../../components/Button';
import Logo from '../../components/Logo';
import { useTranslation } from "react-i18next";

export function Tag({ kernel }) {

    const [tags, setTags] = useState([]);
    const [activeTags, setActiveTags] = useState([]);

    const navigate = useNavigate()

    const installProcess = JSON.parse(localStorage.getItem('installProcess') || '{}');

    const codeType = installProcess?.codeType;
    const freeCode = codeType == 'free';
    const noCode = installProcess?.noCode;

    useEffect(() => {
        ( async () => {
            const response = await kernel.api.get('/dohzel/tags')
            if (response.error) {
                return;
            }
            setTags(response.data.map(tag => ({...tag, collapsed: false, active: false})));
            const storeTags = installProcess?.tags;
            setActiveTags(storeTags ? storeTags : response.data.filter((tag) => tag.default).map((tag) => tag.name))
        })()
    }, []);

    const finishTagBlocking = () => {
        localStorage.setItem('installProcess', JSON.stringify({...installProcess, tags: activeTags}))
        freeCode && !noCode ? 
            navigate('/install/Logs') 
        : 
            navigate('/install/Device')
    }

    const collapseTag = (currentTag) => {
        const alltags = [...tags];
        const _tag = alltags.find(tag => tag.name === currentTag.name);
        _tag.collapsed = !currentTag.collapsed;
        setTags(alltags);
    };

    const activeTag = (currentTag) => {
        const foundTag = activeTags.find((tag) => tag == currentTag.name);
        if(!foundTag) {
            setActiveTags([...activeTags, currentTag.name])
        }
        else {
            setActiveTags(activeTags.filter((tag) => tag !== currentTag.name))
        }
        // const alltags = [...tags];
        // const _tag = alltags.find(tag => tag.name === currentTag.name);
        // _tag.active = !currentTag.active;
        // setTags(alltags);
    };


    const { t, i18n, ready } = useTranslation();

    if (ready) {
        return (
            <Content back={localStorage?.getItem('enrollCodeBack') ?? "PREVIOUS"} backTransition="outRight" topAlign={true}>
                <div className='container' id="page" style={{...styles.container, height:'90dvh', width:'90dvw', paddingTop: 40, paddingBottom: 40}}>
                    <div  style={{ ...styles.container }}>
                        <Logo /> 
                        <div style={styles.title}>
                            <Title centered>{t('Tag.title')}</Title>
                        </div>

                        <div style={styles.subTitle}>
                            <Paragraph centered>
                                {t('Tag.content')}
                            </Paragraph>                        
                        </div>

                        <div style={styles.forwarding}>
                            {
                                tags && tags.map(tag => (
                                    <div style={{marginBottom:25}}>
                                        <span onClick={() => collapseTag(tag)} style={styles.collapseTitle}> 
                                            <Icon style={{color: "#FFFFFF", marginRight: 5}} icon={tag.collapsed ? 'fa-chevron-down' : 'fa-chevron-right'} />
                                            {tag.brief ? tag.brief : tag.name}
                                        </span>
                                        <Switch modifier="material" style={{float: 'right'}} checked={activeTags.includes(tag.name)} onChange={() => activeTag(tag)} />
                                        {
                                            tag.collapsed &&
                                            <div style={styles.collapseContent}>
                                                <SmallText>{tag.description}</SmallText>
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                            <div style={styles.buttonContent}>
                                <Button onClick={() => finishTagBlocking()} modifier="cta">
                                    {t('Tag.continueButton')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    container: {
        textAlign: 'center',
    },
    title: {
        fontWeight: 700,
        fontSize: 40,
    },
    collapseTitle: {
        float: 'left', 
        textAlign: 'left', 
        //marginBottom: 20, 
        fontWeight: 500, 
        width: '80%'
    },
    subTitle: {
        /* margin: 'auto',
        maxWidth: 600, */
        marginBottom: 60,
        marginTop: 30
    },
    collapseContent: {
        display: 'inline-flex',
        width: '100%',
        paddingLeft: 20,
        marginTop: 20,
        marginBottom:10
    },
    centerContent: {
        margin: 30,
    },
    content: {
        fontSize: 30,
    },
    buttonContent: {
        margin: 30,
        marginBottom:0,
        display: 'block',
        //backgroundColor: 'red',
        //display:'flex'
    },
    questionContainer: {
        backgroundColor:'red'
    },
    forwarding: {
        //overflowY: 'scroll',
        //maxWidth: 600,
        width:'100%',
        fontSize: 20,
        //padding: 20,
        marginTop: 0,
        // height: '53vh',
        display: 'flex',
        flexDirection:'column',
        margin: 'initial'
    },
    question: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: 18,
        padding: 5
    },
    button: {
        margin: 20
    }
}