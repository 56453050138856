import { useState } from 'react';
import { Col, ProgressCircular, Row } from 'react-onsenui';
import { useNavigate } from "react-router-dom";
import { kernel } from '../../App';
import { Content } from '../../Templates';
import { Form } from '../../Templates/Form';
import Logo from './../../components/Logo';
import Title from './../../components/Title';
import Button from './../../components/Button';
import { useTranslation, Trans } from "react-i18next";

import {
    notification,
} from 'antd';

const CSScenter = {
    position: "relative",
    textAlign: "center",
}

export function Signup({
}) {

    const [status, setStatus] = useState({})
    const [help, setHelp] = useState({})
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const { t, i18n, ready } = useTranslation();

    const onRegister = async () => {
        const values = {...user}
        setLoading(true)
        const response = await kernel.api.post('/user/register', values)
        if (response.error) {
            notification.error({
                message: t("Settings.error"),
                description: <>{response.error}</>
            })

            if (response.fields) {
                const newStatus = {}
                const newHelp = {}
                for (var field in values) {
                    newStatus[field] = t("Success")
                    newHelp[field] = null
                }
                for (var field of response.fields) {
                    newStatus[field[0]] = t("Error")
                    newHelp[field[0]] = field[1]
                }

                setStatus(newStatus)
                setHelp(newHelp)
            }
            setLoading(false)
            return
        }
        notification.success({
            message: `Thanks for the registration`
        })
        navigate('/settings');
    }

    const fields = [
        {
            name: 'firstName',
            placeholder: t("SignUp.firstName"),
            type: 'text',
            defaultValue: ''
        },
        {
            name: 'lastName',
            placeholder: t("SignUp.lastName"),
            type: 'text',
            defaultValue: ''
        },
        {
            name: 'lang',
            placeholder: t("SignUp.language"),
            type: 'language',
            defaultValue: 'fr'
        },
        {
            name: 'email',
            placeholder: t("SignUp.email"),
            type: 'text',
            defaultValue: ''
        },
        {
            name: 'password1',
            placeholder: t("SignUp.password"),
            type: 'password',
            defaultValue: ''
        },
        {
            name: 'password2',
            placeholder: t("SignUp.passwordCheck"),
            type: 'password',
            defaultValue: ''
        }
    ]
    
    if (ready) {
        return (
            <Content back="/settings" backTransition="outRight" topAlign={true}>
                <div id='page' className='container' style={{ overflowY: 'scroll', maxHeight: '90vh', padding:'0 15px' }}>
                
                <Logo />
                        
                <Title centered bold>{t("SignUp.title")}</Title> 

                <div className='form-container'>
                    <Form help={help} status={status} values={user} setValues={setUser} fields={fields}/>
                    <div> 
                        <div className='btn-container'>
                            {
                                !loading ? 
                                    <Button onClick={onRegister} modifier="cta">
                                        {t("SignUp.button")}
                                    </Button>
                                :
                                    <ProgressCircular indeterminate />
                            }
                            </div>
                            <div className="center">
                            <span className='no-account-btn' onClick={() => navigate('/session/login')} style={{color: "#FFFFFF", textDecoration: 'underline'}} modifier="quiet">
                                {t("SignUp.alreadySignUp")}
                            </span>
                        </div>
                        {/* <Col>
                            <Button onClick={() => navigate('/session/login')} style={{color: "#FFFFFF"}} modifier="quiet">
                                I have an account
                            </Button>
                        </Col> */}
                    </div>
                </div>
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}