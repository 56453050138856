import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Content } from '../Templates'
import Title from '../components/Title';
import Paragraph from '../components/Paragraph';
import Button from '../components/Button';
import { ProgressCircular } from 'react-onsenui';
import { notification } from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from "react-i18next";

import { Transition } from '../App'

export function Explain({ kernel }) {
    const [loaded] = useState(true)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const { t, i18n, ready } = useTranslation();

    useEffect(() => {
        // if (kernel.api.token) {
        //     if (kernel.api.lastContact > 0)
        //         return (navigate("/dashboard"))
        //     else
        //         navigate("/dashboard")
        // }

    }, [kernel])

    useEffect(() => {

        Transition(['#page'], () => { }, "inFade", 2000)

        // contact
        var stop = false
        var timer = null
        async function checkLastContact() {
            timer = null
            // check by ios app
            if (kernel.device.iosApp === true) {
                const isInstalled = await kernel.device.ios.settingsIsInstalled()
                if (!isInstalled)
                    await kernel.device.ios.settingsSetDoH(kernel.api.doh)

                const isActivated = await kernel.device.ios.settingsIsActivated()
                if (isActivated === true && isInstalled === true && kernel.api.token && kernel.api.lastContact > 0) {
                    setLoading(true)
                    setTimeout(() => {
                        Transition(['#page'], () => navigate("/dashboard"), "outLeft")
                    }, 1000)
                    return
                }
            }

            // check by android app
            if (kernel.device.androidApp === true) {
                const isInstalled = await kernel.device.android.settingsIsInstalled()
                // if (!isInstalled)
                //     await kernel.device.ios.settingsSetDoH(kernel.api.doh)

                const isActivated = await kernel.device.android.settingsIsActivated()

                if (isActivated === true && isInstalled === true && kernel.api.token && kernel.api.lastContact > 0) {
                    setLoading(true)
                    setTimeout(() => {
                        Transition(['#page'], () => navigate("/dashboard"), "outLeft")
                    }, 1000)
                    return
                }
            }

            // or check by last contact
            if (kernel.api.lastContact > 0 && !kernel.device.iosApp === true && !kernel.device.androidApp === true) {
                setLoading(true)
                return (Transition(['#page'], () => navigate("/dashboard"), "outLeft"))
            }

            timer = setTimeout(checkLastContact, 1000)
        }
        timer = setTimeout(checkLastContact)
        return (() => {
            stop = true
            if (timer) clearTimeout(timer)
        })
    }, [kernel]);

    useEffect(() => Transition(['#page'], () => { }, "inRight"), [loaded])

    
    

    // Taken from device page 

    var data = {
        joinToken: kernel.config.joinToken
    }

    const reconstituteConfig = () => {
        const installProcess = JSON.parse(localStorage.getItem('installProcess') || '{}');
        const codeType = installProcess?.codeType;
        data.name = installProcess?.name;

        if (installProcess?.code) {
            data.code = installProcess?.code;
        }
        data.tags = installProcess.tags;
        if (!installProcess?.noCode && installProcess?.logToProfile) {
            data.logToProfile = installProcess?.logToProfile;
        }
        if (!codeType || (codeType == 'free' && (!data.tags || (!installProcess?.noCode && !data.logToProfile))) || (codeType == 'mdm' && !data.code)) {
            navigate("/install/welcome");
        }
    }

    async function enroll() {
        
        const deviceToken = localStorage.getItem("deviceToken")
        const dohToken = localStorage.getItem("dohToken")
        
        if (!deviceToken || !dohToken) {
            setLoading(true)
            reconstituteConfig()
            const ret = await kernel.api.post('/dohzel/device/enroll', data)
            if (ret.error) {
                notification.warning({
                    message: 'Can not enroll device',
                    placement: 'bottomRight',
                    description: ret.error,
                });
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            }
            else {
                notification.success({
                    placement: 'bottomRight',
                    message: 'Device enrolled',
                });
                localStorage.setItem("deviceToken", ret.data.token)
                localStorage.setItem("dohToken", ret.data.doh)
                kernel.api.token = ret.data.doh;

                if (kernel.device.browser.device && kernel.device.androidApp === true) {
                    kernel.device.android.settingsCopyDoh(`${ret.data.doh}.${kernel.config.dot?.split('/')?.[0]}`)
                    notification.success({placement: 'bottomRight',message: 'Copied'})
                }

                await kernel.api.beat(true)
                setLoading(false);
                // await downloadMobileconfig()
            }
        }
    }

    async function iosEnroll() {
        await enroll();
        if (kernel.device.iosApp === true) {
            kernel.device.ios.settingsOpen()
        }
        openSettings();
    }

    function openSettings() {
        if (kernel.device.iosApp === true) {
            kernel.device.ios.settingsOpen()
        }
    }


    async function downloadMobileconfig() {
        setLoading(true)
        await enroll();
        const ret = await kernel.api.stream('/dohzel/device/mobileconfig')
        if (!ret) {
            notification.warning({
                message: 'Can not get file, try again later',
                placement: 'bottomRight',
                description: ret.error,
            });
        }
        else {
            const url = window.URL.createObjectURL(ret);
            window.location.href = url
            window.URL.revokeObjectURL(url);
            await openSettings()
        }
        setLoading(false);
    }

    async function openAndroidSettings() {
        setLoading(true)
        if (kernel.api.dot) {
            kernel.device.android.settingsCopyDoh(kernel.api.dot?.split('/')?.[0])
            notification.success({placement: 'bottomRight',message: 'Copied'});
            setLoading(false);
        }
        else {  
            await enroll();
        }
        // copyDot();
        if (kernel.device.androidApp === true)
            kernel.device.android.settingsOpen()
    }

    if (ready) {
        return (
            <Content back="/dashboard" backTransition="outRight" topAlign={true}>
                <div id="page" style={{ overflowY: 'scroll', maxHeight: '90vh'}}>
                    <div className='container' style={styles.container}>
                        <Title centered bold>{t("Explain.title")}</Title>
                            
                        <div style={styles.content}>
                            <Paragraph centered>
                            <strong>DOHzel</strong> {t("Explain.description")}
                            </Paragraph>

                            {kernel.device.browser.device.brand === "Apple" && kernel.device.iosApp !== true ?
                                <>
                                    {/* <Title centered bold>Apple {kernel.device.browser.os.name} Profile</Title> */}
                                    {/* For Mac */}
                                    {kernel.device.browser.os.name === "Mac" ?
                                        <>
                                            <div style={{ paddingTop: 30, paddingBottom:30, textAlign: "left" }}>
                                                <ol className="steps">
                                                    <li><Trans i18nKey="Device.macStep1"></Trans></li>
                                                    <li><Trans i18nKey="Device.macStep2"></Trans></li>
                                                    <li><Trans i18nKey="Device.macStep3"></Trans></li>
                                                    <li><Trans i18nKey="Device.macStep4"></Trans></li>
                                                    <li><Trans i18nKey="Device.macStep5"></Trans></li>
                                                    <li><Trans i18nKey="Device.macStep6"></Trans></li>
                                                </ol>
                                            </div>
                                        </>
                                        : null}

                                    {/* For iPhone */}
                                    {kernel.device.browser.os.name === "iOS" ?
                                    <ol className="steps">
                                                    <li><Trans i18nKey="Device.iosWebStep1"></Trans></li>
                                                    <li><Trans i18nKey="Device.iosWebStep2"></Trans></li>
                                                    <li><Trans i18nKey="Device.iosWebStep3"></Trans></li>
                                                    <li><Trans i18nKey="Device.iosWebStep4"></Trans></li>
                                                </ol>
                                        : null}
                                        {
                                            !loading ? 
                                                <Button modifier="cta" onClick={downloadMobileconfig}>
                                                   {t('Device.downloadButton')}
                                                </Button>
                                            :
                                            <ProgressCircular indeterminate />
                                        }

                                </>
                                : null}

                                {kernel.device.browser.device.brand === "Apple" && kernel.device.iosApp === true ?
                                <>
                                    {/* <Title>Apple iOS app</Title> */}
                                    <ol className='steps'>
                                        <li><Trans i18nKey="Device.iosAppStep1"></Trans></li>
                                        <li><Trans i18nKey="Device.iosAppStep2"></Trans></li>
                                        <li><Trans i18nKey="Device.iosAppStep3"></Trans></li>
                                        <li><Trans i18nKey="Device.iosAppStep4"></Trans></li>
                                    </ol>

                                    <Button modifier="cta" loading={loading} onClick={iosEnroll} style={{
                                        fontWeight: 600, color: "#001E2D",
                                        backgroundColor: "#CCD3D7"
                                    }}>
                                        {t("Device.openButton")}
                                    </Button>
                                </>
                                : null}

                                {kernel.device.browser.device && kernel.device.androidApp === true ?
                                <>
                                    {/* <Title centered bold>Android app</Title> */}
                                    <ol className='steps'>
                                        <li><Trans i18nKey="Device.androidStep1"></Trans></li>
                                        <li><Trans i18nKey="Device.androidStep2"></Trans></li>
                                        <li><Trans i18nKey="Device.androidStep3"></Trans></li>
                                        <li><Trans i18nKey="Device.androidStep4"></Trans></li>
                                        <li><Trans i18nKey="Device.androidStep5"></Trans></li>
                                        <li><Trans i18nKey="Device.androidStep6"></Trans></li>
                                    </ol>
                                    
                                        {
                                            loading ? 
                                            <ons-icon
                                                style={{ fontSize: 50}}
                                                icon="md-spinner"
                                                spin>
                                            </ons-icon>
                                            :
                                            <Button modifier="cta" loading={loading} onClick={openAndroidSettings} style={{
                                                fontWeight: 600, color: "#001E2D",
                                                backgroundColor: "transparent"
                                            }}>
                                                <CopyOutlined /> {t('Device.copyDownloadButton')}
                                                </Button>
                                        }
                                            {/* <CopyToClipboard text={kernel.api.dot}
                                                onCopy={() => {
                                            notification.success({
                                                placement: 'bottomRight',
                                                message: 'Copied',
                                            })
                                        }}>
                                    </CopyToClipboard> */}
                                    
                                </>
                                : null}

                                {kernel.device.browser.device.brand !== "Apple" && kernel.device.androidApp !== true ?
                                <>
                                    <div style={{ padding: '20px 0', fontSize: 20 }}>
                                        <Paragraph centered>
                                            {t('Davice.unsupptedArchitecture')}
                                        </Paragraph>                     
                                    </div>
                                    <div>
                                        <CopyToClipboard text={kernel.api.doh}
                                            onCopy={() => {
                                                notification.success({
                                                    placement: 'bottomRight',
                                                    message: 'Copied',
                                                })
                                            }}>
                                            <Button modifier="cta" loading={loading} style={{
                                                fontWeight: 600, color: "#001E2D",
                                                backgroundColor: "transparent"
                                            }}>
                                                <CopyOutlined /> {t('Device.copyButton')}
                                            </Button>
                                        </CopyToClipboard>
                                    </div>
                                </>
                                : null}



                        </div>
                    </div>
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    container: {
        textAlign: 'center',
    },
    title: {
        fontWeight: 600,
        fontSize: 55
    },
    input: {
        color: '#FFFFFF',
        fontSize: 40,
    },
    centerContent: {
    },
    content: { 
        margin: 'auto',
        fontSize: 30,
        margin: 10
    },
    buttonContent: {
        margin: 30,
        display: 'block'
    },
    description: {
        margin: 30
    },
    questionContainer: {
    },
    question: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: 18,
        padding: 5
    },
    button: {
        margin: 20
    }
}